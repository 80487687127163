<template>
  <div class="d-flex flex-wrap gap">
    <filter-menu
      :filter="dataFilter"
      :auto-filter-type="autoFilterType"
      :consolidable="consolidable"
      :readonly="readonly"
      :label="computedLabel"
      @setConsolidated="setConsolidated($event)"
      @clearSelection="clearSelection()"
      start-with-selected-only-mode
    >
    </filter-menu>
  </div>
</template>

<script>
import FilterParam from '@/pages/search/controllers/filter-param'

export default {
  components: {
    FilterMenu: () => import('@/pages/search/components/filter/filter-menu')
  },
  computed: {
    computedLabel () {
      return this.label ?? this.$t('t.Filter')
    }
  },
  data () {
    return {
      dataFilter: new FilterParam(),
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  methods: {
    emitDocument () {
      const stringified = JSON.stringify(this.dataFilter)
      const doc = stringified ? JSON.parse(stringified) : {}
      if (!this.lodash.isEqual(this.value, doc)) {
        this.$emit('input', doc)
      }
    },
    clearSelection (category) {
      this.dataFilter.clearSelectedValues(category)
      this.dataDocumentEmit()
    },
    incExcSwitchValue (item) {
      return this.dataFilter.incExcSwitchValue(item)
    },
    async setSelectedValues (item, incExcSwitchValue) {
      await this.dataFilter.updateSelectedValues(item, incExcSwitchValue)
      this.dataDocumentEmit()
    },
    setConsolidated (v) {
      if (this.consolidable) {
        this.dataFilter.setConsolidated(v)
        this.dataDocumentEmit()
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (v) {
        this.dataFilter = new FilterParam(v)
        this.dataDocumentEmit()
      }
    }
  },
  props: {
    autoFilterType: String,
    consolidable: Boolean,
    label: String,
    readonly: Boolean,
    targetSearch: Object,
    value: Object
  }
}
</script>
